<template>
	<v-card class="elevation-4" tile>
		<!-- Research paper tabs -->
		<v-toolbar flat>
			<v-tabs v-model="current_tab" fixed-tabs slider-color="wr_blue_1">
				<v-tab
					:key="tab.id"
					v-for="tab in tabs"
					:href="'#' + tab.link"
					:class="`${tab.color} wr_blue_1--text font-weight-bold`"
				>
					<!-- Else tabs will have translated names (If any) -->
					<span :class="{ 'text-truncate': tab.origin == 'private' }">
						<span v-if="tab.origin == 'private'" class="caption"
							>{{
								$t("app.chapter_param", {
									chapter_count: tab.chapter_count,
								})
							}}<br />
						</span>
						<span :class="{ 'text-truncate': tab.origin == 'private' }">
							{{ $te(tab.title) ? $t(tab.title) : tab.title }}
						</span>
					</span>
				</v-tab>
			</v-tabs>
		</v-toolbar>

		<v-tabs-items v-model="current_tab">
			<v-tab-item :key="tab.id" v-for="tab in tabs" :value="tab.link">
				<component
					:is="tab.component"
					:section_component="tab.section_component"
					:chapter_id="tab.id"
				></component>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import { researchPaperTabsMixin } from "@/mixins/researchPaper/tabsMixin.js";
import TableOfContent from "@/components/shared/tools/researchPaper/tabs/TableOfContent.vue";
import WritingTemplate from "@/components/shared/tools/researchPaper/tabs/WritingTemplate.vue";
import { mapFields } from "vuex-map-fields";

export default {
	name: "ToolsResearchPaper",

	mixins: [researchPaperTabsMixin],

	data() {
		return {
			show_preview: false,
		};
	},

	computed: {
		...mapFields("toolsResearchPaper", {
			current_tab: "current_tab",
		}),

		...mapState({
			template: (state) => state.toolsResearchPaper.template,
		}),
	},

	components: {
		TableOfContent,
		WritingTemplate,
	},
};
</script>

<style></style>
