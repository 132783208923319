import { findFromArray } from "@/utils/helpers";
import { mapState, mapGetters } from "vuex";

export const researchPaperChapterMixin = {
	props: {
		chapter_id: {
			required: true,
			type: Number,
		},
	},

	computed: {
		...mapState({
			researchTemplate: (state) => state.researchPaper.template,
			toolsResearchPaperTemplate: (state) => state.toolsResearchPaper.template,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
			isStudent: "user/isStudent",
			isAdmin: "user/isAdmin",
		}),

		isCollaborationRoute() {
			return this.$route.params && this.$route.params.id;
		},

		template() {
			if (this.isCollaborationRoute) {
				return this.toolsResearchPaperTemplate;
			}
			return this.researchTemplate;
		},

		chapter() {
			var chapter = findFromArray(this.template, "id", this.chapter_id);
			if (!chapter) {
				return {};
			}
			return chapter;
		},

		subLevel() {
			return this.template.filter((item) => {
				return item.level == this.chapter.level && item.sub_level;
			});
		},
	},
};
