<template>
	<v-card flat>
		<v-card-text v-if="toc.length">
			<v-treeview
				:items="toc"
				open-on-click
				return-object
				activatable
				:active.sync="active"
			>
				<!-- Middle slot -->
				<template v-slot:label="{ item }">
					<div>
						<span :class="{ 'text-decoration-underline': item.sub_level }">{{
							$te(item.title) ? $t(item.title) : item.title
						}}</span>
					</div>
				</template>
			</v-treeview>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import { researchPaperTOCMixin } from "@/mixins/researchPaper/tocMixin.js";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "ToolsResearchPaperTOC",

	mixins: [researchPaperTOCMixin],

	data() {
		return {
			isArrayHavingItem,
			active: [],
		};
	},

	computed: {
		...mapState({
			template: (state) => state.toolsResearchPaper.template,
		}),
	},
};
</script>
