<template>
	<v-row no-gutters class="white pb-3" style="height:120px">
		<template v-for="(item, index) in images">
			<v-col v-if="index < 3" sm="1" :key="index">
				<v-img
					:src="require(`@/assets/images/logos/${item}.png`)"
					contain
					width="90"
				></v-img>
			</v-col>
			<v-col
				v-else
				sm="9"
				:key="index"
				:align="$vuetify.rtl ? 'left' : 'right'"
			>
				<v-img
					:src="require(`@/assets/images/logos/${item}.png`)"
					contain
					width="100"
				></v-img>
			</v-col>
		</template>
	</v-row>
</template>

<script>
export default {
	name: "ResearchPaperPosterLogo",

	computed: {
		images() {
			return [
				`menorah`,
				// `merkaz_madanei`,
				// `huji`,
				// `idea`
			];
		},
	},
};
</script>

<style></style>
