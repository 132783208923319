import { mapGetters } from "vuex";
import { convertToKebabCase } from "@/utils/helpers";

export const researchPaperTabsMixin = {
	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),

		isCollaborationRoute() {
			return this.$route.params && this.$route.params.id;
		},

		canEvaluate() {
			return (
				!this.isCollaborationRoute ||
				(this.isCollaborationRoute && this.isMentor)
			);
		},

		tabs() {
			// Start tab
			let toc_tab = {
				id: 1,
				uuid: "0",
				color: "blueLighten5",
				component: "TableOfContent",
				section_component: null,
				link: "tab-toc",
				origin: "fixed",
				title: "app.table_of_content",
				chapter_count: 0,
			};

			// End tab (Set id=0 because total number of tabs are dynamic)
			let evaluation_tab = {
				id: 0,
				uuid: "0",
				color: "blueLighten5",
				component: "WritingTemplate",
				section_component: "Evaluation",
				link: "tab-evaluation",
				origin: "fixed",
				title: "app.evaluation",
				chapter_count: 0,
			};

			var tabs = [];

			// Push start tab at first index
			tabs.push(toc_tab);

			var count = 0;
			var chapterCount = 1;

			this.template.map((chapter) => {
				// If template is not divided in sub levels then only proceed
				if (!chapter.sub_level) {
					// Prepare a slug type title for tabs reference
					var slugify_title = convertToKebabCase(chapter.title);

					if (chapter.chapter_type == "bib" && this.isCollaborationRoute) {
						return;
					}

					let obj = {};
					obj.id = chapter.id;
					obj.uuid = chapter.uuid;
					obj.color = this.$defines.RESEARCH_PAPER_TABS_COLORS[count];
					obj.component = "WritingTemplate";
					obj.section_component = "GenericSection";
					obj.link = "tab-" + slugify_title;
					obj.origin = chapter.origin;
					obj.title = chapter.title;
					obj.chapter_count = 0;

					// Specific Components will use separate component
					if (chapter.chapter_type == "intro") {
						obj.section_component = "Introduction";
					}
					if (chapter.chapter_type == "bib") {
						obj.section_component = "Bibliography";
					}
					if (chapter.chapter_type == "poster") {
						obj.section_component = "ResearchPoster";
					}

					// For origin="private" (user created) set title the chapter_count
					if (chapter.origin == "private") {
						obj.chapter_count = chapterCount;
						chapterCount++;
					}

					tabs.push(obj);

					// Increment count for another tab
					count++;
				}

				// Restart count if it crossed limit
				if (count == this.$defines.RESEARCH_PAPER_TABS_COLORS.length) {
					count = 0;
				}
			});

			/**
			 * Push evaluation and poster tab only if user is mentor and
			 * collaborating OR user is the owner of paper.
			 */
			if (this.canEvaluate) {
				// FIXME:: Hide eveluation tab for all journeys
				// tabs.push(evaluation_tab);
			}

			// Return prepared tabs
			return tabs;
		},
	},
};
