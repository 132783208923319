<template>
	<v-dialog
		:value="dialog"
		scrollable
		width="900px"
		persistent
		attach="#poster"
	>
		<AppDialog
			:heading="{
				label: $t('app.import_text'),
			}"
			:action="{
				label: 'app.okay',
				event: 'import-text',
			}"
			@close="$emit('close')"
			@import-text="emitEvent()"
		>
			<template v-slot:dialog-content>
				<v-card outlined>
					<v-row class="pa-4" justify="space-between">
						<v-col cols="5">
							<v-list dense v-if="chapters && chapters.length">
								<v-list-item-group v-model="selected" color="info">
									<v-list-item v-for="(item, i) in chapters" :key="i">
										<v-list-item-content>
											<v-list-item-title
												v-text="item.title"
											></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-col>

						<v-divider vertical></v-divider>

						<v-col class="d-flex content-display-wr">
							<v-scroll-y-transition mode="out-in">
								<v-card-text
									v-if="noChapterSelected"
									class="text-h6 text-center grey--text text--lighten-1 font-weight-light"
								>
									{{ $t("app.select_chapter") }}
								</v-card-text>
								<v-card
									v-if="!noChapterSelected && selectedChapter"
									:key="selectedChapter.id"
									class="mx-auto overflow-y-auto"
									flat
									min-height="500"
									width="100%"
									max-height="500"
								>
									<v-card-text>
										<h3 class="text-h5 text-center">
											{{ selectedChapter.title }}
										</h3>
									</v-card-text>
									<v-divider class="mb-3"></v-divider>
									<Loading v-if="fetching" scale=".6"></Loading>
									<v-row v-else tag="v-card-text" v-html="html"></v-row>
								</v-card>
							</v-scroll-y-transition>
						</v-col>
					</v-row>
				</v-card>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import { researchPaperTOCMixin } from "@/mixins/researchPaper/tocMixin.js";
import { mapState } from "vuex";
import { flatArrayOfObjects } from "@/utils/helpers";
import { referenceManagerMixin } from "@/mixins/researchPaper/referenceManagerMixin.js";
import Loading from "@/components/plugins/Loading.vue";

export default {
	name: "ImportTextDialog",

	mixins: [researchPaperTOCMixin, referenceManagerMixin],

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
	},

	components: {
		AppDialog,
		Loading,
	},

	data: () => ({
		chapters: null,
		selected: null,
		html: null,
		fetching: false,
	}),

	mounted() {
		this.init();
	},

	computed: {
		...mapState({
			template: (state) => state.researchPaper.template,
			sources: (state) => state.source.list,
		}),

		noChapterSelected() {
			return this.selected == null;
		},

		selectedChapter() {
			if (this.noChapterSelected) return null;

			return this.chapters[this.selected];
		},
	},

	watch: {
		selectedChapter(newVal) {
			if (newVal) {
				// clean pre-existing html
				this.html = null;
				if (newVal.chapter_type == "bib") {
					this.prepareBibliographyContent();
				} else {
					this.getHtml();
				}
			}
		},
	},

	methods: {
		init() {
			try {
				this.$loader.start();

				let result = this.toc.filter((item) => ![0, -1, -2].includes(item.id));
				result.forEach((element) => {
					if (element.children) {
						element.children = element.children.filter((item) => item.id != -1);
					}
				});
				this.chapters = flatArrayOfObjects(result, "children");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getHtml() {
			try {
				this.fetching = true;

				let response = await this.$store.dispatch("etherpad/getHTML", {
					pad_id: this.chapters[this.selected].uuid,
				});

				this.html = response ? response.html : null;

				this.fetching = false;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		emitEvent() {
			try {
				this.$loader.start();

				if (this.noChapterSelected || !this.selectedChapter) {
					this.$emit("close");
				} else {
					this.$emit("import-text", {
						imported_from:
							this.selectedChapter.chapter_type == "bib"
								? "bibliography"
								: this.selectedChapter.uuid,
						text: this.html,
					});
				}
				this.chapters = this.selected = this.html = null;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async prepareBibliographyContent() {
			try {
				this.fetching = true;

				let references = await this.prepareReferenceFormat(this.sources);
				let result = `<ul style='font-style:italic;padding-${
					this.$vuetify.rtl ? "right" : "left"
				}:24px'>`;
				for (let i = 0; i < references.length; i++) {
					result += "<li>" + references[i].text + "</li>";
				}
				result += "</ul>";

				this.html = result;

				this.fetching = false;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
>>> .content-display-wr {
	word-break: break-word !important;
}
</style>
