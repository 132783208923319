<template>
	<v-card flat>
		<!-- Chapter -->
		<v-card tile outlined>
			<v-card-text min-height="120">
				<EtherPad :pad_id="chapter.uuid" />
			</v-card-text>
		</v-card>

		<!-- Sub chapters -->
		<template v-if="subLevel.length">
			<v-card
				:key="sub_chapter.id"
				v-for="sub_chapter in subLevel"
				tile
				outlined
			>
				<v-card-text>
					<!-- Title -->
					<div class="subtitle-1 text-decoration-underline">
						{{ $t(sub_chapter.title) }}
					</div>

					<!-- Subtitle -->
					<div class="mt-1">
						{{
							sub_chapter.content
								? sub_chapter.content
								: $t("app.you_will_get_the_desc_of_chapters")
						}}
					</div>

					<!-- Editor -->
					<EtherPad :pad_id="sub_chapter.uuid" />
				</v-card-text>
			</v-card>
		</template>
	</v-card>
</template>

<script>
import EtherPad from "@/components/shared/tools/etherpad/Pad.vue";
import { researchPaperChapterMixin } from "@/mixins/researchPaper/chapterMixin.js";

export default {
	name: "ResearchPaperGenericSection",

	mixins: [researchPaperChapterMixin],

	components: {
		EtherPad,
	},
};
</script>
