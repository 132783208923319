<template>
	<v-card-text class="pt-0">
		<!-- Some required actions -->
		<v-btn-toggle :class="['wr-btn-toggle me-2']" dense tile :borderless="true">
			<template v-for="(item, index) in actions">
				<v-btn
					:key="index"
					v-tooltip="tooltipConfig(item.tooltip)"
					@click="
						item.method == 'toggleScreenMode'
							? toggleScreenMode()
							: item.method == 'downloadPoster'
							? downloadPoster()
							: $emit('handle-function-call', item.method)
					"
				>
					<v-icon>{{ $vuetify.icons.values[item.icon] }}</v-icon>
				</v-btn>
			</template>
		</v-btn-toggle>

		<v-btn-toggle :class="['wr-btn-toggle me-2']" dense tile :borderless="true">
			<!-- For background color -->
			<v-menu
				:close-on-content-click="false"
				:attach="is_full_screen ? `#poster` : false"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						:disabled="noNodeSelected"
						v-on="on"
						v-tooltip="tooltipConfig('app.bg_color')"
						:style="{
							borderBottom: `4px solid ${background_color} !important`,
						}"
					>
						<v-icon>$vuetify.icons.values.color_fill</v-icon>
					</v-btn>
				</template>
				<v-card>
					<v-card-text class="pa-0">
						<v-color-picker v-model="background_color" flat />
					</v-card-text>
				</v-card>
			</v-menu>

			<!-- For text color -->
			<v-menu
				:close-on-content-click="false"
				:attach="is_full_screen ? `#poster` : false"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						:disabled="noNodeSelected || backgroundNodeSelected"
						class="ms-2"
						v-on="on"
						v-tooltip="tooltipConfig('app.text_color')"
						:style="{
							borderBottom: `4px solid ${text_color} !important`,
						}"
					>
						<v-icon>$vuetify.icons.values.color_text</v-icon>
					</v-btn>
				</template>
				<v-card>
					<v-card-text class="pa-0">
						<v-color-picker v-model="text_color" hide-inputs flat />
					</v-card-text>
				</v-card>
			</v-menu>
		</v-btn-toggle>

		<!-- border -->
		<v-btn-toggle class="wr-btn-toggle me-2" :borderless="true" dense tile>
			<v-btn
				v-tooltip="
					tooltipConfig(
						selected_node && selected_node.border
							? 'app.remove_border'
							: 'app.add_border',
					)
				"
				:disabled="noNodeSelected || backgroundNodeSelected"
				@click="$emit('toggle-border')"
			>
				<v-icon>{{
					$vuetify.icons.values[
						selected_node && selected_node.border
							? "border_outside"
							: "border_none"
					]
				}}</v-icon>
			</v-btn>
		</v-btn-toggle>

		<!-- Import data from research paper tabs -->
		<v-btn-toggle class="wr-btn-toggle me-2" :borderless="true" dense tile>
			<v-btn
				@click="dialog = true"
				:disabled="noNodeSelected || backgroundNodeSelected"
			>
				<v-icon>$vuetify.icons.values.arrow_down</v-icon>&ensp;
				{{ $t("app.import_text") }}
			</v-btn>
		</v-btn-toggle>
		<ImportTextDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
			@import-text="(dialog = false), $emit('import-text', $event)"
		></ImportTextDialog>

		<v-btn-toggle class="wr-btn-toggle me-2" :borderless="true" dense tile>
			<v-btn
				:disabled="
					noNodeSelected || backgroundNodeSelected || headerNodeSelected
				"
			>
				<input
					type="file"
					name="image"
					id="image"
					accept="image/*"
					@change="uploadImage"
				/>
			</v-btn>
		</v-btn-toggle>

		<!-- Zoom Actions -->
		<ZoomController el="#poster-container"></ZoomController>
	</v-card-text>
</template>

<script>
import ZoomController from "@/components/plugins/ZoomController.vue";
import ImportTextDialog from "@/components/research/modules/write/stages/researchPaper/ImportTextDialog.vue";
import { downloadImage } from "@/plugins/htmlToImage";

export default {
	name: "ResearchPaperPosterActions",

	props: {
		selected_node: {
			required: true,
		},
	},

	data() {
		return {
			dialog: false,
			is_full_screen: false,
			text_color: null,
			background_color: null,
		};
	},

	components: {
		ZoomController,
		ImportTextDialog,
	},

	computed: {
		noNodeSelected() {
			return !this.selected_node;
		},

		headerNodeSelected() {
			return this.selected_node.id === this.$defines.POSTER_HEADER;
		},

		backgroundNodeSelected() {
			return this.selected_node.id === this.$defines.POSTER_BACKGROUND;
		},

		actions() {
			return [
				{
					icon: "rectangle_outline",
					tooltip: "app.add_node",
					method: "addNode",
					condition: true,
				},
				{
					icon: this.is_full_screen ? "exit_full_screen" : "full_screen",
					tooltip: this.is_full_screen
						? "app.exit_full_screen"
						: "app.full_screen",
					method: "toggleScreenMode",
					condition: true,
				},
				{
					icon: "download",
					tooltip: "app.download",
					method: "downloadPoster",
					condition: true,
				},
			];
		},
	},

	watch: {
		selected_node(newVal) {
			if (newVal) {
				this.text_color = newVal.color;
				this.background_color = newVal.background;
			}
		},

		background_color(newVal, oldVal) {
			if (newVal != oldVal) {
				this.$emit("update-color", {
					type: "background",
					color: this.background_color,
				});
			}
		},
		text_color(newVal, oldVal) {
			if (newVal != oldVal) {
				this.$emit("update-color", {
					type: "color",
					color: this.text_color,
				});
			}
		},
	},

	created() {
		document.addEventListener("fullscreenchange", (event) => {
			// document.fullscreenElement will point to the element that
			// is in fullscreen mode if there is one. If there isn't one,
			// the value of the property is null.
			this.is_full_screen = document.fullscreenElement;
		});
	},

	methods: {
		tooltipConfig(text) {
			return {
				content: this.$t(text),
				placement: "top",
				targetClasses: ["it-has-a-tooltip"],
				offset: 8,
				classes: ["toggle_btn"],
			};
		},

		toggleScreenMode() {
			let el = document.getElementById("poster");
			if (el) {
				if (this.is_full_screen) {
					return document.exitFullscreen();
				}
				el.requestFullscreen();
			}
		},

		downloadPoster() {
			try {
				this.$loader.start();
				// remove if any node is selected (otherwise selected styles will also be dowlnloaded)
				this.$emit("reset-selected-node");
				// start download after 1 sec (to prepare somethings)
				setTimeout(async () => {
					let image_prepared = await downloadImage("#poster-container");
					// if image is ready, stop the loader
					if (image_prepared) {
						this.$loader.stop();
					}
				}, 1000);
			} catch (error) {
				this.$announce.error(error);
				this.$loader.stop();
			}
		},

		async uploadImage(e) {
			try {
				this.$loader.start();

				// Check if any image upload or not
				if (!e.target || !e.target.files || !e.target.files[0]) return;

				// validate the image
				let image = e.target.files[0];
				if (!image.type.includes("image")) {
					throw new Error("app.ntfy.err.invalid_image", {
						cause: "werCustom",
					});
				}

				// prepare form data to send image as attachment
				let fd = new FormData();
				fd.append("attachment", image);
				// send image to the backend server
				let response = await this.$store.dispatch(
					"researchPaper/uploadImageToPoster",
					fd,
				);

				// emit event to the parent component
				this.$emit("image-uploaded", response);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/tooltip.scss";
</style>
