import { convertToKebabCase } from "@/utils/helpers";
import { mapGetters } from "vuex";

export const researchPaperTOCMixin = {
	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),

		isCollaborationRoute() {
			return this.$route.params && this.$route.params.id;
		},

		canEvaluate() {
			return (
				!this.isCollaborationRoute ||
				(this.isCollaborationRoute && this.isMentor)
			);
		},

		toc() {
			var toc = [];

			this.template.map((chapter) => {
				/**
				 * If the chapter does not have any sub level that means it's
				 * a parent chapter.
				 */
				if (!chapter.sub_level) {
					// do not show bibliography chapter on collaboration route.
					if (chapter.chapter_type == "bib" && this.isCollaborationRoute) {
						return;
					}

					let obj = {};
					obj.id = chapter.id;
					obj.chapter_type = chapter.chapter_type;
					obj.uuid = chapter.uuid;
					obj.children = [];
					obj.level = chapter.level;
					obj.link = "tab-" + convertToKebabCase(chapter.title);
					obj.origin = chapter.origin;
					obj.title = chapter.title;
					obj.content = chapter.content;

					// Find children of the current obj
					let children = this.template.filter((item) => {
						return item.level == obj.level && item.sub_level;
					});

					// If children found then assign a property to each one for redirect to parent.
					if (children.length) {
						obj.children = children;
						obj.children.map((child) => {
							child.link = obj.link;
						});
					}

					/**
					 * Chpaters which has level less than 50, are eligible
					 * to add subchapters. (Do only for owner)
					 */
					if (obj.level < 50 && !this.isCollaborationRoute) {
						let add_subchpter = {
							id: -1,
							parent: {
								id: obj.id,
								title: obj.title,
								level: obj.level,
							},
						};
						obj.children.push(add_subchpter);
					}

					// Push prepared obj to toc array
					toc.push(obj);
				}
			});

			// FIXME:: Hide eveluation tab for all journeys
			// Push evluate only if allowed
			// if (this.canEvaluate) {
			// 	toc.push({
			// 		id: -2,
			// 		children: [],
			// 		level: 0,
			// 		link: "tab-evaluation",
			// 		origin: "fixed",
			// 		title: "app.evaluation",
			// 	});
			// }

			// Only owner can add new chapters
			if (!this.isCollaborationRoute && !this.isWorkRoute) {
				toc.push({
					id: 0,
					children: [],
					level: 0,
					link: null,
					origin: "fixed",
					title: "app.add_new_chapter",
					icon: "$vuetify.icons.values.plus",
					action: "showChapterDialog",
				});
			}

			return toc;
		},
	},
};
