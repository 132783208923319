<template>
	<ToolsResearchPaper></ToolsResearchPaper>
</template>

<script>
import ToolsResearchPaper from "@/components/shared/tools/researchPaper/Index.vue";
import { mapState } from "vuex";

export default {
	name: "StudentResearchPaper",

	components: {
		ToolsResearchPaper,
	},

	mounted() {
		this.getResearchPaper();
	},

	methods: {
		async getResearchPaper() {
			try {
				this.$loader.start();

				var response = await this.$store.dispatch(
					"toolsResearchPaper/getTemplate",
					{
						clear: false,
						student_id: this.$route.params.id,
					},
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style></style>
