var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"pt-0"},[_c('v-btn-toggle',{class:['wr-btn-toggle me-2'],attrs:{"dense":"","tile":"","borderless":true}},[_vm._l((_vm.actions),function(item,index){return [_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipConfig(item.tooltip)),expression:"tooltipConfig(item.tooltip)"}],key:index,on:{"click":function($event){item.method == 'toggleScreenMode'
						? _vm.toggleScreenMode()
						: item.method == 'downloadPoster'
						? _vm.downloadPoster()
						: _vm.$emit('handle-function-call', item.method)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values[item.icon]))])],1)]})],2),_c('v-btn-toggle',{class:['wr-btn-toggle me-2'],attrs:{"dense":"","tile":"","borderless":true}},[_c('v-menu',{attrs:{"close-on-content-click":false,"attach":_vm.is_full_screen ? "#poster" : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipConfig('app.bg_color')),expression:"tooltipConfig('app.bg_color')"}],style:({
						borderBottom: ("4px solid " + _vm.background_color + " !important"),
					}),attrs:{"disabled":_vm.noNodeSelected}},on),[_c('v-icon',[_vm._v("$vuetify.icons.values.color_fill")])],1)]}}])},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.background_color),callback:function ($$v) {_vm.background_color=$$v},expression:"background_color"}})],1)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"attach":_vm.is_full_screen ? "#poster" : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipConfig('app.text_color')),expression:"tooltipConfig('app.text_color')"}],staticClass:"ms-2",style:({
						borderBottom: ("4px solid " + _vm.text_color + " !important"),
					}),attrs:{"disabled":_vm.noNodeSelected || _vm.backgroundNodeSelected}},on),[_c('v-icon',[_vm._v("$vuetify.icons.values.color_text")])],1)]}}])},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"hide-inputs":"","flat":""},model:{value:(_vm.text_color),callback:function ($$v) {_vm.text_color=$$v},expression:"text_color"}})],1)],1)],1)],1),_c('v-btn-toggle',{staticClass:"wr-btn-toggle me-2",attrs:{"borderless":true,"dense":"","tile":""}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
				_vm.tooltipConfig(
					_vm.selected_node && _vm.selected_node.border
						? 'app.remove_border'
						: 'app.add_border'
				)
			),expression:"\n\t\t\t\ttooltipConfig(\n\t\t\t\t\tselected_node && selected_node.border\n\t\t\t\t\t\t? 'app.remove_border'\n\t\t\t\t\t\t: 'app.add_border',\n\t\t\t\t)\n\t\t\t"}],attrs:{"disabled":_vm.noNodeSelected || _vm.backgroundNodeSelected},on:{"click":function($event){return _vm.$emit('toggle-border')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values[ _vm.selected_node && _vm.selected_node.border ? "border_outside" : "border_none" ]))])],1)],1),_c('v-btn-toggle',{staticClass:"wr-btn-toggle me-2",attrs:{"borderless":true,"dense":"","tile":""}},[_c('v-btn',{attrs:{"disabled":_vm.noNodeSelected || _vm.backgroundNodeSelected},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("$vuetify.icons.values.arrow_down")]),_vm._v("  "+_vm._s(_vm.$t("app.import_text"))+" ")],1)],1),(_vm.dialog)?_c('ImportTextDialog',{attrs:{"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false},"import-text":function($event){(_vm.dialog = false), _vm.$emit('import-text', $event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e(),_c('v-btn-toggle',{staticClass:"wr-btn-toggle me-2",attrs:{"borderless":true,"dense":"","tile":""}},[_c('v-btn',{attrs:{"disabled":_vm.noNodeSelected || _vm.backgroundNodeSelected || _vm.headerNodeSelected}},[_c('input',{attrs:{"type":"file","name":"image","id":"image","accept":"image/*"},on:{"change":_vm.uploadImage}})])],1),_c('ZoomController',{attrs:{"el":"#poster-container"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }