<template>
	<v-expansion-panels v-model="evaluation" multiple>
		<v-expansion-panel>
			<v-expansion-panel-header>
				{{ $t("app.student_self_evaluation") }}
				<small class="pl-4">{{
					$t("app.research_paper.evaluation.student.subtitle")
				}}</small>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-card>
					<v-card-text>
						<div class="subtitle-1">
							{{ $t("app.research_paper.evaluation.student.subtitle_2") }}
						</div>
						<v-row no-gutters>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.not_at_all") }}
									</v-card-text>
								</v-card>
							</v-col>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.lot_to_improve") }}
									</v-card-text>
								</v-card>
							</v-col>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.good") }}
									</v-card-text>
								</v-card>
							</v-col>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.very_good") }}
									</v-card-text>
								</v-card>
							</v-col>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.excellent") }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text>
						<div>
							<div>
								{{ $t("app.research_paper.evaluation.student.content_1") }}
							</div>
							<v-slider
								v-model="student.content_1"
								min="0"
								max="20"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div>
								{{ $t("app.research_paper.evaluation.student.content_2") }}
							</div>
							<v-slider
								v-model="student.content_2"
								min="0"
								max="20"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div>
								{{ $t("app.research_paper.evaluation.student.content_3") }}
							</div>
							<v-slider
								v-model="student.content_3"
								min="0"
								max="20"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div>
								{{ $t("app.research_paper.evaluation.student.content_4") }}
							</div>
							<v-slider
								v-model="student.content_4"
								min="0"
								max="20"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div>
								{{ $t("app.research_paper.evaluation.student.content_5") }}
							</div>
							<v-slider
								v-model="student.content_5"
								min="0"
								max="20"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							{{ $t("app.research_paper.evaluation.comments") }}
						</div>
						<v-textarea
							outlined
							dense
							color="greenAccent2"
							background-color="white"
							:placeholder="$t('app.placeholder.add_your_comments')"
						></v-textarea>
						<div class="text-right font-weight-bold">
							{{ $t("app.total_out_of_points", { score: studentScore }) }}
						</div>
					</v-card-text>
				</v-card>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>
				{{ $t("app.mentor_evaluation") }}
				<small>{{ $t("app.research_paper.evaluation.mentor.subtitle") }}</small>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-card>
					<v-card-text>
						<v-row no-gutters>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.not_at_all") }}
									</v-card-text>
								</v-card>
							</v-col>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.lot_to_improve") }}
									</v-card-text>
								</v-card>
							</v-col>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.good") }}
									</v-card-text>
								</v-card>
							</v-col>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.very_good") }}
									</v-card-text>
								</v-card>
							</v-col>
							<v-col>
								<v-card outlined>
									<v-card-text>
										{{ $t("app.excellent") }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text>
						<div>
							<div
								v-html="$t('app.research_paper.evaluation.mentor.content_1')"
							></div>
							<v-slider
								v-model="mentor.content_1"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div
								v-html="$t('app.research_paper.evaluation.mentor.content_2')"
							></div>
							<v-slider
								v-model="mentor.content_2"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div
								v-html="$t('app.research_paper.evaluation.mentor.content_3')"
							></div>
							<v-slider
								v-model="mentor.content_3"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div
								v-html="$t('app.research_paper.evaluation.mentor.content_4')"
							></div>
							<v-slider
								v-model="mentor.content_4"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div
								v-html="$t('app.research_paper.evaluation.mentor.content_5')"
							></div>
							<v-slider
								v-model="mentor.content_5"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div
								v-html="$t('app.research_paper.evaluation.mentor.content_6')"
							></div>
							<v-slider
								v-model="mentor.content_6"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div
								v-html="$t('app.research_paper.evaluation.mentor.content_7')"
							></div>
							<v-slider
								v-model="mentor.content_7"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div
								v-html="$t('app.research_paper.evaluation.mentor.content_8')"
							></div>
							<v-slider
								v-model="mentor.content_8"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<v-card-text>
							<div class="headline">
								{{ $t("app.research_paper.evaluation.mentor.content_9") }}
							</div>
						</v-card-text>
						<div>
							<div>
								<v-text-field
									outlined
									dense
									color="greenAccent2"
									background-color="white"
									:label="$t('app.research_paper.evaluation.mentor.content_10')"
								></v-text-field>
							</div>
							<v-slider
								v-model="mentor.content_9"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
						<div>
							<div>
								<v-text-field
									outlined
									dense
									color="greenAccent2"
									background-color="white"
									:label="$t('app.research_paper.evaluation.mentor.content_10')"
								></v-text-field>
							</div>
							<v-slider
								v-model="mentor.content_10"
								min="0"
								max="10"
								step="1"
								color="amberDarken1"
								track-color="amberDarken1"
								thumb-label="always"
								thumb-color="wr_indigo_2"
							></v-slider>
						</div>
					</v-card-text>
					<v-card-text>
						<div>
							{{ $t("app.research_paper.evaluation.comments") }}
						</div>
						<v-textarea
							outlined
							dense
							color="greenAccent2"
							background-color="white"
							:placeholder="$t('app.placeholder.add_your_comments')"
						></v-textarea>
						<div class="text-right font-weight-bold">
							{{ $t("app.total_out_of_points", { score: mentorScore }) }}
						</div>
					</v-card-text>
				</v-card>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>
<script>
export default {
	name: "ResearchPaperEvaluation",

	data() {
		return {
			evaluation: [0],
			student: {},
			mentor: {},
		};
	},

	computed: {
		studentScore: function() {
			var score = 0;
			for (const property in this.student) {
				score += this.student[property];
			}
			return score;
		},

		mentorScore: function() {
			var score = 0;
			for (const property in this.mentor) {
				score += this.mentor[property];
			}
			return score;
		},
	},
};
</script>
