var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.board)?_c('v-card',{attrs:{"flat":"","id":"poster","ripple":false}},[(!_vm.isMentor)?_c('Actions',{attrs:{"selected_node":_vm.seletedNode},on:{"import-text":_vm.importText,"update-color":_vm.updateColor,"toggle-border":_vm.toggleBorder,"reset-selected-node":_vm.resetSelectedNode,"image-uploaded":_vm.onImageUpload,"handle-function-call":_vm.handleFunctionCall}}):_vm._e(),_c('v-card-text',{staticClass:"pt-0"},[(_vm.backgroundNode)?_c('v-card',{class:{
				'selected-node': _vm.selected_node_id == _vm.$defines.POSTER_BACKGROUND,
			},attrs:{"id":"poster-container","width":"100%","height":"1000px","tile":"","ripple":false},on:{"click":function($event){return _vm.setNodeId(_vm.$defines.POSTER_BACKGROUND)}}},[_c('Logo'),(_vm.headNode)?_c('v-card',{class:[
					'overflow-hide',
					{
						'selected-node': _vm.selected_node_id == _vm.$defines.POSTER_HEADER,
						'default-border': _vm.headNode.border,
					} ],style:({
					zIndex: 1,
					background: _vm.headNode.background,
				}),attrs:{"flat":"","tile":"","height":"80","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.setNodeId(_vm.$defines.POSTER_HEADER)}}},[_c('v-card-text',{staticClass:"font-weight-bold text-h4",style:({ color: ((_vm.headNode.color) + " !important") }),attrs:{"align":"center"},domProps:{"innerHTML":_vm._s(_vm.headNode.text)}})],1):_vm._e(),(_vm.dynamicNodes && _vm.dynamicNodes.length)?_c('v-card',{style:({ background: _vm.backgroundNode.background }),attrs:{"height":"800","flat":"","tile":"","id":"downloadable-container-wr","ripple":false}},_vm._l((_vm.dynamicNodes),function(item,index){return _c('vue-resizable',{key:index,ref:"resizableComponent",refInFor:true,staticClass:"resizable resizable-wr",attrs:{"fitParent":true,"width":item.width,"height":item.height,"left":item.left,"top":item.top,"id":("resize-" + (item.id)),"dragSelector":_vm.isMentor ? '.empty' : '.drag-container-wr',"active":_vm.isMentor ? [] : ['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt']},on:{"resize:move":function($event){return _vm.eHandler(item.id, $event)},"resize:start":function($event){return _vm.eHandler(item.id, $event)},"resize:end":function($event){return _vm.eHandler(item.id, $event)},"drag:move":function($event){return _vm.eHandler(item.id, $event)},"drag:start":function($event){return _vm.eHandler(item.id, $event)},"drag:end":function($event){return _vm.eHandler(item.id, $event)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var _obj;

				var hover = ref.hover;return [_c('v-card',{staticClass:"drag-container-wr",attrs:{"flat":"","ripple":false}},[(hover && !_vm.isMentor)?_c('v-icon',{staticClass:"delete-node-icon cursor-wr",style:(( _obj = {}, _obj[_vm.$vuetify.rtl ? 'left' : 'right'] = '7px', _obj )),on:{"click":function($event){$event.stopPropagation();return _vm.removeNode(item.id)}}},[_vm._v(" $vuetify.icons.values.cancel ")]):_vm._e(),_c('div',{attrs:{"id":item.id}},[_c('v-card',{class:[
										'overflow-hidden',
										{
											'selected-node': _vm.selected_node_id == item.id,
											'default-border': item.border,
										} ],style:({
										background: item.background,
									}),attrs:{"flat":"","tile":"","height":item.height,"width":item.width},on:{"click":function($event){$event.stopPropagation();return _vm.setNodeId(item.id)}}},[(item.image)?_c('v-img',{attrs:{"contain":"","aspect-ratio":"1","width":item.width,"height":item.height,"src":("" + (_vm.$environments.base_api_url) + (item.image.attachment))},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)}):_c('v-card-text',{style:({
											fontSize: '16px',
											color: ((item.text == '' ? 'grey' : item.color) + " !important"),
										}),domProps:{"innerHTML":_vm._s(
											item.text != ''
												? item.text
												: _vm.$t('app.please_import_text')
										)}})],1)],1)],1)]}}],null,true)})],1)}),1):_vm._e()],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }