<template>
	<v-card flat tile>
		<!-- Toolbar -->
		<slot name="toolbar"></slot>

		<!-- Help content -->
		<slot name="help-content"></slot>

		<!-- Section -->
		<v-card flat tile>
			<v-card-text class="headline text-center text-decoration-underline">
				{{ chapter.title }}
			</v-card-text>

			<v-card-text
				v-if="chapter.origin == 'private'"
				class="pt-0"
				align="center"
			>
				{{
					chapter.content
						? chapter.content
						: $t("app.you_will_get_the_desc_of_chapters")
				}}
			</v-card-text>

			<component :is="section_component" :chapter_id="chapter_id"></component>
		</v-card>

		<!-- checklist -->
		<slot name="checklist"></slot>

		<!-- mentor suggestion -->
		<slot name="mentor-suggestion"></slot>

		<!-- FIXME:: uncomment after Ofek's event -->
		<!-- Footer -->
		<!-- <v-card-text>
			<div class="text-center">
				<a v-for="(item, index) in actions" :key="index">
					{{ $t(item.title) }}
					<span
						v-if="index + 1 < actions.length"
						class="black--text font-weight-bold"
						>&ensp;/&ensp;</span
					>
				</a>
			</div>
		</v-card-text> -->

		<!-- <v-divider></v-divider>

		<v-card-title class="text-overline font-italic">
			<a>Previous</a>
			<v-spacer></v-spacer>
			<a>Next</a>
		</v-card-title> -->
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import * as sectionComponents from "@/components/research/modules/write/stages/researchPaper/sections/index.js";
import { researchPaperChapterMixin } from "@/mixins/researchPaper/chapterMixin.js";

export default {
	name: "ResearchPaperLayout",

	mixins: [researchPaperChapterMixin],

	props: {
		section_component: {
			required: true,
			type: String,
		},
	},

	components: {
		...sectionComponents,
	},

	computed: {
		actions() {
			return [
				{
					title: "app.print",
				},
				{
					title: "app.download_word",
				},
				{
					title: "app.download_pdf",
				},
				{
					title: "app.full_doc_mode",
				},
				{
					title: "app.send_to_mentor",
				},
				{
					title: "app.word_count",
				},
				{
					title: "app.other_functions",
				},
			];
		},
	},

	methods: {
		goToScroll(target) {
			var model = target.replace("#", "");
			this.$vuetify.goTo(target, { container: "#the-view-port" });
			this[model] = 0;
		},
	},
};
</script>
