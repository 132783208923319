<template>
	<v-card flat>
		<v-card :key="index" v-for="(item, index) in subLevel" tile outlined>
			<v-card-text>
				<div class="subtitle-1 text-decoration-underline black--text">
					{{ $t(item.title) }}
				</div>

				<div v-html="$t('app.research_paper.intro.' + item.sub_level)"></div>

				<EtherPad :pad_id="item.uuid" />
			</v-card-text>
		</v-card>
	</v-card>
</template>

<script>
import EtherPad from "@/components/shared/tools/etherpad/Pad.vue";
import { researchPaperChapterMixin } from "@/mixins/researchPaper/chapterMixin.js";

export default {
	name: "ResearchPaperIntroduction",

	mixins: [researchPaperChapterMixin],

	components: {
		EtherPad,
	},
};
</script>
