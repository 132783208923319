<template>
	<PosterLayout
		v-if="poster && poster.data && poster.data.length >= 2"
		v-model="poster"
		:board="poster"
		@update="updatePoster()"
	></PosterLayout>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import PosterLayout from "./layout/Index";
import { mapState, mapGetters } from "vuex";

export default {
	name: "ResearchPaperPoster",

	components: {
		PosterLayout,
	},

	data() {
		return {
			poster: null,
		};
	},

	computed: {
		...mapState({
			current_tab: (state) => state.researchPaper.current_tab,
			current_student: (state) => state.student.current_student,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
		}),
	},

	watch: {
		current_tab(newVal, oldVal) {
			if (this.poster && newVal && newVal == "tab-poster") {
				this.updatePosterIfAnyPadUpdated();
			}
		},
	},

	mounted() {
		this.initPoster();
	},

	methods: {
		async isPosterExists() {
			let payload = {
				category: this.$defines.SYSTEM_CATEGORY,
				type: this.$defines.WHITEBOARD_TYPES.RESEARCH_POSTER,
			};

			if (this.isMentor) {
				payload.student_id = this.current_student.id;
				return await this.$store.dispatch("mentorBoard/getOfStudent", payload);
			}

			await this.$store.dispatch("whiteboard/list", payload);
		},

		async createPoster() {
			let response = await this.$store.dispatch("whiteboard/create", {
				uuid: uuidv4(),
				category: this.$defines.SYSTEM_CATEGORY,
				type: this.$defines.WHITEBOARD_TYPES.RESEARCH_POSTER,
			});
			return response;
		},

		createDefaultNodes() {
			this.poster.data.push(
				{
					id: this.$defines.POSTER_BACKGROUND,
					background: "#ffffff",
				},
				{
					id: this.$defines.POSTER_HEADER,
					text: this.$t("app.poster_title"),
					background: "#ffffff",
					color: "#000000",
					imported_from: "",
					border: true,
				},
			);
		},

		async updatePoster() {
			try {
				await this.$store.dispatch("whiteboard/update", this.poster);
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async updatePosterIfAnyPadUpdated() {
			try {
				this.$loader.start();
				// Mentor can not update the board of any student
				if (this.isMentor) return;

				// if no poster data, return
				if (!this.poster.data || !this.poster.data.length) return;
				// update the text nodes of poster
				this.poster.data.map(async (item) => {
					// do not update if it's imported from bibliography
					if (
						item.text &&
						item.imported_from &&
						item.imported_from != "bibliography"
					) {
						let response = await this.$store.dispatch("etherpad/getHTML", {
							pad_id: item.imported_from,
						});
						if (response && item.text !== response.html) {
							item.text = response.html;
						}
					}
				});
				// update at the backend side
				await this.updatePoster();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async setUpBoardForMentor(payload) {
			if (!payload.length) {
				throw new Error("app.ntfy.err.not_created_board", {
					cause: "werCustom",
				});
			} else {
				this.poster = payload[0];
			}
		},

		async setUpBoardForStudent(payload) {
			if (payload && payload.length) {
				this.poster = payload[0];
				// update the poster nodes' text if any pad changes
				await this.updatePosterIfAnyPadUpdated();
			}
			// create if not exists
			else {
				let poster = await this.createPoster();
				this.poster = poster;
			}
			if (!this.poster.data.length) {
				// create default nodes
				this.createDefaultNodes();
				// update poster
				await this.updatePoster();
			}
		},

		async initPoster() {
			try {
				this.$loader.start();
				// check if poster exists
				let response = await this.isPosterExists();
				// set board according to user role
				this.isMentor
					? await this.setUpBoardForMentor(response)
					: await this.setUpBoardForStudent(response);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style></style>
