<template>
	<v-card flat id="poster" v-if="board" :ripple="false">
		<Actions
			v-if="!isMentor"
			:selected_node="seletedNode"
			@import-text="importText"
			@update-color="updateColor"
			@toggle-border="toggleBorder"
			@reset-selected-node="resetSelectedNode"
			@image-uploaded="onImageUpload"
			@handle-function-call="handleFunctionCall"
		></Actions>

		<v-card-text class="pt-0">
			<v-card
				v-if="backgroundNode"
				id="poster-container"
				width="100%"
				height="1000px"
				tile
				:ripple="false"
				:class="{
					'selected-node': selected_node_id == $defines.POSTER_BACKGROUND,
				}"
				@click="setNodeId($defines.POSTER_BACKGROUND)"
			>
				<!-- images section -->
				<Logo></Logo>

				<!-- poster header -->
				<v-card
					v-if="headNode"
					flat
					tile
					height="80"
					:ripple="false"
					:style="{
						zIndex: 1,
						background: headNode.background,
					}"
					:class="[
						'overflow-hide',
						{
							'selected-node': selected_node_id == $defines.POSTER_HEADER,
							'default-border': headNode.border,
						},
					]"
					@click.stop="setNodeId($defines.POSTER_HEADER)"
				>
					<v-card-text
						align="center"
						class="font-weight-bold text-h4"
						v-html="headNode.text"
						:style="{ color: `${headNode.color} !important` }"
					>
					</v-card-text>
				</v-card>

				<!-- Poster components -->
				<v-card
					height="800"
					v-if="dynamicNodes && dynamicNodes.length"
					flat
					tile
					id="downloadable-container-wr"
					:style="{ background: backgroundNode.background }"
					:ripple="false"
				>
					<vue-resizable
						v-for="(item, index) in dynamicNodes"
						:key="index"
						:fitParent="true"
						:width="item.width"
						:height="item.height"
						:left="item.left"
						:top="item.top"
						class="resizable resizable-wr"
						ref="resizableComponent"
						:id="`resize-${item.id}`"
						:dragSelector="isMentor ? '.empty' : '.drag-container-wr'"
						:active="
							isMentor ? [] : ['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt']
						"
						@resize:move="eHandler(item.id, $event)"
						@resize:start="eHandler(item.id, $event)"
						@resize:end="eHandler(item.id, $event)"
						@drag:move="eHandler(item.id, $event)"
						@drag:start="eHandler(item.id, $event)"
						@drag:end="eHandler(item.id, $event)"
					>
						<v-hover v-slot:default="{ hover }">
							<v-card flat class="drag-container-wr" :ripple="false">
								<!-- Remove node icon -->
								<v-icon
									v-if="hover && !isMentor"
									@click.stop="removeNode(item.id)"
									class="delete-node-icon cursor-wr"
									:style="{ [$vuetify.rtl ? 'left' : 'right']: '7px' }"
								>
									$vuetify.icons.values.cancel
								</v-icon>
								<div :id="item.id">
									<v-card
										flat
										tile
										:height="item.height"
										:width="item.width"
										:style="{
											background: item.background,
										}"
										:class="[
											'overflow-hidden',
											{
												'selected-node': selected_node_id == item.id,
												'default-border': item.border,
											},
										]"
										@click.stop="setNodeId(item.id)"
									>
										<!-- If image node -->
										<v-img
											v-if="item.image"
											contain
											aspect-ratio="1"
											:width="item.width"
											:height="item.height"
											:src="
												`${$environments.base_api_url}${item.image.attachment}`
											"
										>
											<template v-slot:placeholder>
												<ImageLoader></ImageLoader>
											</template>
										</v-img>
										<!-- If text node -->
										<v-card-text
											v-else
											v-html="
												item.text != ''
													? item.text
													: $t('app.please_import_text')
											"
											:style="{
												fontSize: '16px',
												color: `${
													item.text == '' ? 'grey' : item.color
												} !important`,
											}"
										></v-card-text>
									</v-card>
								</div>
							</v-card>
						</v-hover>
					</vue-resizable>
				</v-card>
			</v-card>
		</v-card-text>
	</v-card>
</template>

<script>
import Logo from "./Logo";
import Actions from "./Actions";
import { mapGetters } from "vuex";
import ImageLoader from "@/components/plugins/ImageLoader";
import {
	generateRandomString,
	isArrayHavingItem,
	findElIndex,
	findFromArray,
} from "@/utils/helpers";

export default {
	name: "ResearchPaperPosterLayout",

	props: {
		board: {
			required: true,
		},
	},

	data() {
		return {
			selected_node_id: null,
		};
	},

	components: {
		Logo,
		Actions,
		ImageLoader,
	},

	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),

		nodes() {
			return this.board.data;
		},

		dynamicNodes() {
			// head node and background node are fixed, filter them.
			return this.nodes.filter(
				(item) =>
					!isArrayHavingItem(
						[this.$defines.POSTER_HEADER, this.$defines.POSTER_BACKGROUND],
						item.id,
					),
			);
		},

		selectedNodeIndex() {
			return findElIndex(this.nodes, "id", this.selected_node_id);
		},

		selectedNodeExists() {
			return this.selectedNodeIndex !== -1;
		},

		seletedNode() {
			return this.selectedNodeExists
				? this.nodes[this.selectedNodeIndex]
				: null;
		},

		headNode() {
			return findFromArray(this.nodes, "id", this.$defines.POSTER_HEADER);
		},

		backgroundNode() {
			return findFromArray(this.nodes, "id", this.$defines.POSTER_BACKGROUND);
		},
	},

	methods: {
		resetSelectedNode() {
			this.selected_node_id = null;
		},

		setNodeId(node_id) {
			// mentor can not select the node
			if (this.isMentor) return;

			this.selected_node_id = node_id;
		},

		addNode() {
			this.nodes.push({
				id: generateRandomString(),
				top: 10,
				left: 550,
				width: 400,
				height: 200,
				text: "",
				background: "#ffffff",
				color: "#000000",
				border: true,
				imported_from: "",
			});
			// update poster
			this.$emit("update");
		},

		async removeNode(node_id) {
			try {
				this.$loader.start();
				// Find node to be remove
				let index = findElIndex(this.nodes, "id", node_id);
				if (index === -1) return;
				// Check if this node has an image
				if (this.nodes[index].image) {
					await this.$store.dispatch("researchPaper/removePosterImage", {
						image_id: this.nodes[index].image.id,
					});
				}
				// Else, splice it from array
				this.nodes.splice(index, 1);
				// update poster
				this.$emit("update");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		importText(payload) {
			try {
				if (this.selectedNodeExists) {
					// if image is set previously, reset it
					this.nodes[this.selectedNodeIndex].image = "";
					// Now, set the text details
					this.nodes[this.selectedNodeIndex].imported_from =
						payload.imported_from;
					this.nodes[this.selectedNodeIndex].text = payload.text;
					// update poster
					this.$emit("update");
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		updateColor(payload) {
			try {
				if (this.selectedNodeExists) {
					this.nodes[this.selectedNodeIndex][payload.type] = payload.color;
					// update poster
					this.$emit("update");
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		toggleBorder() {
			try {
				if (this.selectedNodeExists) {
					this.nodes[this.selectedNodeIndex].border = !this.nodes[
						this.selectedNodeIndex
					].border;
					// update poster
					this.$emit("update");
					// reset the selected node
					this.resetSelectedNode();
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		onImageUpload(payload) {
			try {
				if (this.selectedNodeExists) {
					// if text is set previously, reset it
					this.nodes[this.selectedNodeIndex].text = "";
					this.nodes[this.selectedNodeIndex].imported_from = "";
					// Now, set the image details
					this.nodes[this.selectedNodeIndex].image = payload;
					// update poster
					this.$emit("update");
					// reset the selected node
					this.resetSelectedNode();
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		handleFunctionCall(method_name) {
			try {
				this[method_name]();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		eHandler(node_id, event) {
			try {
				let index = this.nodes.findIndex((item) => item.id == node_id);
				["width", "height", "top", "left"].forEach((dimension) => {
					this.nodes[index][dimension] = event[dimension];
				});

				// update poster
				if (isArrayHavingItem(["resize:end", "drag:end"], event.eventName)) {
					this.$emit("update");
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
>>> .selected-node {
	outline: 2px solid black !important;
}
>>> .default-border {
	outline: 1px solid grey;
}
>>> .delete-node-icon {
	position: absolute;
	top: 7px;
	z-index: 1;
	height: 15px;
	width: 15px;
	color: tomato;
}
>>> .downloadable-container-wr {
	cursor: default;
}
</style>
