<template>
	<StudentResearchPaper></StudentResearchPaper>
</template>

<script>
import StudentResearchPaper from "@/components/shared/student/ResearchPaper.vue";

export default {
	name: "AdminStudentResearchPaper",

	components: {
		StudentResearchPaper,
	},
};
</script>
