<template>
	<div>
		<ResearchPaperLayout
			:section_component="section_component"
			:chapter_id="chapter_id"
		/>
	</div>
</template>

<script>
import ResearchPaperLayout from "@/components/research/modules/write/stages/researchPaper/Layout.vue";

export default {
	name: "ToolsResearchPaperWritingTemplate",

	props: {
		section_component: {
			required: true,
			type: String,
		},
		chapter_id: {
			required: true,
			type: Number,
		},
	},

	components: {
		ResearchPaperLayout,
	},
};
</script>
