<template>
	<v-card flat>
		<v-card-text>
			<v-card outlined class="px-2 pt-8 " min-height="500">
				<v-card-text
					class="py-1 font-italic black--text"
					v-for="(item, index) in references"
					:key="index"
				>
					<!-- Show checked icon for used references else hide it -->
					<v-tooltip
						v-if="item.used_in_research"
						:right="!$vuetify.rtl"
						:left="$vuetify.rtl"
						color="wr_indigo_2"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-icon size="22" color="success" v-bind="attrs" v-on="on">
								$vuetify.icons.values.check_outline
							</v-icon>
						</template>
						<span>This reference is used.</span>
					</v-tooltip>
					<v-icon v-else color="transparent"
						>$vuetify.icons.values.check_outline
					</v-icon>
					<!-- edit a reference -->
					<v-btn icon @click="processSourceEditing(item.id)">
						<v-icon color="info" size="20">$vuetify.icons.values.edit</v-icon>
					</v-btn>
					{{ item.text }}
				</v-card-text>
			</v-card>

			<PopulateReferenceDialog
				v-if="reference_dialog"
				:dialog="reference_dialog"
				:source="source"
				:to_be_edit="true"
				@close="reference_dialog = false"
				@update-to-etherpad="onReferenceUpdateReq"
			></PopulateReferenceDialog>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PopulateReferenceDialog from "@/components/research/modules/write/stages/researchPaper/PopulateReferenceDialog.vue";
import { referenceManagerMixin } from "@/mixins/researchPaper/referenceManagerMixin.js";

export default {
	name: "ResearchPaperBibliography",

	mixins: [referenceManagerMixin],

	data() {
		return {
			references: null,
			reference_dialog: false,
			source: null,
		};
	},

	components: {
		PopulateReferenceDialog,
	},

	computed: {
		...mapState({
			current_research_tab: (state) => state.researchPaper.current_tab,
			sources: (state) => state.source.list,
		}),
		...mapGetters({
			userProfile: "user/getProfile",
		}),
	},

	watch: {
		current_research_tab: {
			handler(newVal, oldVal) {
				this.prepareReferences();
			},
			deep: true,
		},
	},

	mounted() {
		this.prepareReferences();
	},

	methods: {
		async prepareReferences() {
			try {
				this.$loader.start();

				// Fetch references from etherpad
				var used_references = await this.$store.dispatch(
					"etherpad/getUsedReferences",
					{
						user_id: this.userProfile.id,
					},
				);
				// Prepare reference of m3 module
				let all_references = await this.prepareReferenceFormat(this.sources);
				// filter, used and ununsed references
				this.references = all_references.map((obj) => {
					let used = used_references.find((item) => item.id == obj.id);
					return { ...obj, used_in_research: used ? true : false };
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async processSourceEditing(source_id) {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("source/get", {
					source_id,
				});
				if (response) {
					this.source = response;

					this.reference_dialog = true;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async onReferenceUpdateReq(payload) {
			try {
				this.$loader.start();

				await this.$store.dispatch("etherpad/updateReference", {
					user_id: this.userProfile.id,
					data: payload,
				});

				await this.prepareReferences();
				this.reference_dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style></style>
